<template lang="pug">
settings-shell.goals-screen
  template(#head)
    h1.title My Goals
  template(#actions v-if="!initializing && anyGoals")
    b-button(type="is-primary" @click="addGoal") Add Goal
  b-loading(:is-full-page="true" :active.sync="initializing" :can-cancel="false")
  .goals(v-if="!initializing && anyGoals")
    .box(v-if="activeGoal")
      h2.subtitle Active Goal
      goal-display(:goal="activeGoal" :date="today")
        b-button.is-small(@click="editGoal(activeGoal)") Edit
    .box(v-if="futureGoals.length")
      h2.subtitle Future Goals
      goal-display.future-goal(:goal="goal" :date="today" v-for="goal in futureGoals" :show-progress="false" :key="goal.id")
        b-button.is-small(@click="editGoal(goal)") Edit
    .box(v-if="pastGoals.length")
      h2.subtitle Past Goals
      goal-display.past-goal(:goal="goal" :date="goal.goalDate" v-for="goal in pastGoals" :key="goal.id")
        b-button.is-small(@click="editGoal(goal)") Edit
  .no-goals-splash(v-if="!initializing && noGoals")
    .box
      h2.subtitle Set your first goal
      goal-form
    .box
      h2.subtitle Stay in control with goals
      p.content
        | Goals let you pick a net worth that you want to hit by a
        | certain date. Once you have a goal, whenever you update your net
        | worth you'll be able to see your progress towards it.
        | You can set any number of goals for yourself. The first goal in
        | the future is your "active" goal.
      p.content
        | Don't worry if you don't hit your goals! You can edit them at any
        | time or just set a new goal instead. The road to financial freedom
        | can be bumpy and it's how we respond to the bumps that matters.

  b-modal(:active.sync="showNewGoal" has-modal-card :can-cancel="['escape']" :trap-focus="true")
    goal-form-dialog
  b-modal(:active.sync="showEditGoal" has-modal-card :can-cancel="['escape']" :trap-focus="true")
    goal-form-dialog(:goal="goalToEdit")
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { filter } from 'lodash'
import dayjs from '@/dayjs'

import GoalForm from '@/components/GoalForm'
import GoalFormDialog from '@/components/GoalFormDialog'
import GoalDisplay from '@/components/GoalDisplay'
import SettingsShell from '@/components/SettingsShell'

export default {
  name: 'GoalsView',
  components: {
    GoalForm,
    GoalFormDialog,
    GoalDisplay,
    SettingsShell
  },
  data() {
    const today = dayjs().toDate()
    return {
      today,
      goalToEdit: null,
      showNewGoal: false,
      showEditGoal: false
    }
  },
  computed: {
    ...mapState('currencies', ['loadingCurrencies']),
    ...mapState('goals', ['loadingGoals']),
    ...mapGetters('goals', ['noGoals', 'anyGoals', 'getActiveGoal']),
    initializing() {
      const { loadingCurrencies, loadingGoals } = this
      return loadingCurrencies || loadingGoals
    },
    activeGoal() {
      return this.getActiveGoal(this.today)
    },
    futureGoals() {
      const { activeGoal } = this
      return filter(
        this.$store.getters['goals/futureGoals'],
        goal => goal !== activeGoal
      ).reverse()
    },
    pastGoals() {
      const { activeGoal } = this
      return filter(
        this.$store.getters['goals/pastGoals'],
        goal => goal !== activeGoal
      )
    }
  },
  methods: {
    addGoal() {
      this.showNewGoal = true
    },
    editGoal(goal) {
      this.goalToEdit = goal
      this.showEditGoal = true
    }
  },
  metaInfo: {
    title: 'My Goals'
  }
}
</script>

<style lang="sass" scoped>
.future-goal ::v-deep .goal-description
  flex-grow: 0
.past-goal + .past-goal
  margin-top: 1.5rem
.subtitle
  margin-bottom: 0.75rem
</style>
